import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Headroom from 'react-headroom';
import { Logo } from '../../../theme/Logo'
import HeaderNav from '../HeaderNav';
import SearchForm from '../Search/SearchForm';
import './Header.scss';
import '../../../theme/Header.scss';
import GatsbyLink from '../GatsbyLink';

const isClient = typeof window !== 'undefined';

class Header extends Component {
  state = {
    searchActive: false,
    navActive: false,
    navInnerHeight: null,
  }
  
  componentDidMount() {
    if (isClient) {
      if (window.innerWidth > 1080) {
        this.setState({ navInnerHeight: null });
      } else {
        this.setState({ navInnerHeight: window.innerHeight - 80 });
      }
    }
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  /* Small function to remove the nav-open
   * On a window resize.
   */
  handleResize = () => {
    if (window.innerWidth > 1080) {
      if(isClient) {
        const childrenDomElements = document.querySelectorAll('.children');
        for (let i = 0; i < childrenDomElements.length; i++) {
          if (childrenDomElements[i].classList.contains('open')) {
            childrenDomElements[i].classList.remove('open')
          }
        }
        if (document.body.classList.contains('nav-open')) {
          this.toggleNav();
        }
        this.setState({ navInnerHeight: null });
      }
    } else if (isClient) {
        this.setState({ navInnerHeight: window.innerHeight - 80 });
      }
  }

  toggleSearch = event => {
    const { searchActive } = this.state;
    if (event) event.preventDefault();
    if (!searchActive) {
      this.closeAll();
      this.setState({ searchActive: true });
    } else {
      this.setState({ searchActive: false });
    }
  };

  toggleNav = event => {
    if (event) event.preventDefault();
    
    const { navActive } = this.state;
    
    if (!navActive) {
      if (isClient) {
        if (!document.body.classList.contains('nav-open')) {
          // simple check to see if we are on desktop or not.
          // If we add the nav-open class then the blur effect will come into play.
          if (window.innerWidth < 1080) {
            document.body.classList.add('nav-open');
          }
        }
      }
      this.setState({
        navActive: true,
        searchActive: false,
      });

    } else {
      if (isClient) {
        document.body.classList.remove('nav-open');
      }
      this.setState({ navActive: false });
    }
  };

  closeAll = event => {
    if (event) event.preventDefault();
    if (isClient) {
      document.body.classList.remove('nav-open');
    }
    this.setState({
      searchActive: false,
      navActive: false,
    });
  };

  enableBodyScroll = () => {
    if (isClient) {
      document.body.classList.remove('nav-open');
    }
  }

  render() {
    const {
      searchActive,
      navActive,
      navInnerHeight
    } = this.state;
    const {
      data,
    } = this.props;
    const {
      primaryNavigation,
      siteSettings,
      wordpressWpSettings
    } = data;
    const { options } = siteSettings
    const { baseUrl } = wordpressWpSettings;
    const { items: navItems } = primaryNavigation;
    const bookingButton = primaryNavigation.items.find(item => item.classes.includes('book'));
    const {mobileNavBottomLeftLink, mobileNavBottomRightLink} = options;
    return (
      <Headroom disableInlineStyles pinStart={50}>
        <header className={options.invertHeaderColours ? 'invert' : ''}>
          <SearchForm
            active={searchActive}
            toggleSearch={this.toggleSearch}
          />
          <div className="header-main">
            <div className="wrap">
              <div className="inner">
                <Logo />
                <div className="nav">
                  <nav className={`nav-inner${navActive ? ' active' : ''}`} style={{height: navInnerHeight}}>
                    <HeaderNav active={navActive} className="navigation" navItems={navItems} baseUrl={baseUrl} toggleNav={this.toggleNav} />
                    <div className="mobile-bottom-nav">
                      {mobileNavBottomLeftLink && (
                        <GatsbyLink className="mobile-bottom-nav-left-link" to={mobileNavBottomLeftLink.url} onClick={this.enableBodyScroll} target={mobileNavBottomLeftLink.target ? mobileNavBottomLeftLink.target : null}>{mobileNavBottomLeftLink.title}</GatsbyLink>
                      )}
                      {mobileNavBottomRightLink && (
                        <GatsbyLink className="mobile-bottom-nav-right-link" to={mobileNavBottomRightLink.url} onClick={this.enableBodyScroll} target={mobileNavBottomRightLink.target ? mobileNavBottomRightLink.target : null}>{mobileNavBottomRightLink.title}</GatsbyLink>
                      )}
                    </div>
                  </nav>
                  <div className="controls">
                    {bookingButton && <GatsbyLink target={bookingButton.target ? bookingButton.target : null} to={bookingButton.url} className="mobile-header-button">{bookingButton.shortLabel}</GatsbyLink>}
                    <button
                      type="button"
                      className={`control-nav icon-hamburger ${navActive ? 'active' : ''}`}
                      onClick={this.toggleNav}
                      aria-label="Toggle Menu"
                    >
                      <span className={`burger ${navActive ? 'active' : ''}`} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Headroom>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        primaryNavigation: wordpressMenusMenusItems(slug: { eq: "primary-navigation" }) {
          items {
            title
            url
            classes
            slug
            object_id
            target
            shortLabel
            children: child_items {
              title
              url
              classes
              object_id
              slug
              target
              children: child_items {
                title
                url
                classes
                object_id
                slug
                target
              }
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            navigationAbovePrimaryMenu
            socialLinksHeader
            contactNumberHeader
            invertHeaderColours
            showSearchIconInMainNavigation
            locations {
              addressDisplay
              email
              phone
            }
            mobileNavBottomLeftLink: leftLink {
              url
              target
              title
            }
            mobileNavBottomRightLink: rightLink {
              url
              target
              title
            }
          }
        }
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
