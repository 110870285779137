import React, { Component, Fragment, useState, useEffect } from 'react';
import GatsbyLink from '../GatsbyLink';
import { decodeEntities } from '../../utils/helpers';
import { Chevron } from '../Svg/Chevron';
import './HeaderNav.scss'

const isClient = typeof window !== 'undefined';

const enableBodyScroll = (toggleNavFn) => {
  if (isClient) {
    document.body.classList.remove('nav-open');
  }
  if (toggleNavFn) {
    toggleNavFn();
  }
}

const NavItem = (props) => {
  const { item, baseUrl, openNavKey, toggleSubNav, toggleNav } = props;
  const {
    slug,
    object_id,
    title,
    url,
    children,
    target,
    shortLabel
  } = item;
  const isOpen = openNavKey === object_id;
  return (
    <span
      key={object_id}
    >
      <GatsbyLink to={url} onClick={() => enableBodyScroll(toggleNav)} target={target ? target : null} activeClassName="active" aria-label={title}>
        <span className="normal-label">{decodeEntities(title)}</span>{shortLabel ? <span className="short-label">{decodeEntities(shortLabel)}</span> : null}
      </GatsbyLink>
      {children && children[0].url !== '#gatsby' && (
        <Fragment>
          <MegaNav baseUrl={baseUrl} childNav={children} isOpen={isOpen} />
          <button
            type="button"
            className={`expand ${isOpen ? 'open' : 'closed'}`}
            onClick={event => toggleSubNav(event, object_id)}
            aria-label="Open Menu"
          >
            <Chevron direction={isOpen ? 'left' : 'right'} className="chevron" />
          </button>
        </Fragment>
      )}
    </span>
  );
}

const MegaNav = (props) => {
  const { childNav, baseUrl, isOpen = false } = props;
  const [activeMegaNavItem, setActiveMegaNavItem] = useState(null);
  const [isTabletDown, setIsTabletDown] = useState(false);
  const resizeCallback = () => setIsTabletDown(window.innerWidth < 1080);
  useEffect(() => {
    if (window && typeof window !== 'undefined') {
      setIsTabletDown(window.innerWidth < 1080);
      window.addEventListener('resize', resizeCallback);
    }
    return () => {
      window.removeEventListener('resize', resizeCallback);
    }
  },[isTabletDown]);
  return (
    <div className={`meganav children ${isOpen ? 'open' : 'close'}`}>
      <div className="wrap">
        <div className="meganav-parent-items">
          <ul>
            {childNav.map((child, index) => {
              const {
                classes,
                object_id,
                slug,
                title,
                url,
                target,
                children,
              } = child;
              const megaNavParentOpen = activeMegaNavItem === index;
              return (
                <li
                  key={index}
                  onMouseOver={isTabletDown ? null : () => setActiveMegaNavItem(index)}
                  onFocus={isTabletDown ? null : () => setActiveMegaNavItem(index)}
                  className={children && children.length > 0 ? 'parent' : ''}
                >
                  <span className={megaNavParentOpen ? 'open' : 'close'}>
                    <GatsbyLink onClick={enableBodyScroll} to={url} target={target ? target : null} activeClassName="active" aria-label={title} decode={true}>{title}</GatsbyLink>
                    {children && children[0].url !== '#gatsby' && (
                      <Fragment>
                        <ul className="mobile-meganav-child-nav-items">
                          {children.map(desktopChild => {
                            return (
                              <li key={desktopChild.url}>
                                <GatsbyLink to={desktopChild.url} onClick={enableBodyScroll} target={desktopChild.target ? desktopChild.target : null} activeClassName="active" aria-label={desktopChild.title} decode={true}>{desktopChild.title}</GatsbyLink>
                              </li>
                            );
                          })}
                        </ul>
                        <button
                          type="button"
                          className={`expand ${activeMegaNavItem === index ? 'open' : 'closed'}`}
                          onClick={() => setActiveMegaNavItem(activeMegaNavItem !== index ? index : null)}
                          aria-label="Open Menu"
                        >
                          <Chevron direction="right" className="chevron" />
                        </button>
                      </Fragment>
                    )}
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
        {activeMegaNavItem !== null && childNav[activeMegaNavItem] && childNav[activeMegaNavItem].children && (
          <div className="meganav-child-items">
            <ul>
              {childNav[activeMegaNavItem].children.map(child => {
                const {
                  classes,
                  object_id,
                  slug,
                  title,
                  url,
                  target,
                } = child;
                return (
                  <li key={url}>
                    <GatsbyLink to={url} onClick={enableBodyScroll} target={target ? target : null} activeClassName="active" aria-label={title} decode={true}>{title}</GatsbyLink>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default class HeaderNav extends Component {
  state = { openNavKey: null };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  }

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey });
  }

  render() {
    const { active, navItems, baseUrl, className, toggleNav } = this.props;
    const { openNavKey } = this.state;
    return (
      <ul className={`${className} ${openNavKey ? 'open' : 'close'}`}>
        {navItems && navItems.map((col, index) => index < 10 && (
          <li
            key={index}
            className={col.classes.join(' ')}
          >
            <NavItem key={col.url} item={col} baseUrl={baseUrl} toggleNav={toggleNav} toggleSubNav={this.toggleSubNav} openNavKey={openNavKey} />
          </li>
        ))}
      </ul>
    );
  }
}
