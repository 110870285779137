/* eslint-disable camelcase */
import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { FaEnvelope, FaPhone, FaMapPin } from 'react-icons/fa'
import GatsbyLink from '../GatsbyLink'
import { SocialLinks } from '../SocialLinks'
import { decodeEntities } from '../../utils/helpers'
import footerAccreditation from '../../images/footer-accreditation.png'
import footerZipPay from '../../images/footer-zip.svg'
import footerHumm from '../../images/footer-humm.svg'
import footerSuper from '../../images/footer-super.png'
import footerAfterpay from '../../images/footer-afterpay.png'
import './Footer.scss'

class Footer extends Component {
  sendEmail(event) {
    event.preventDefault()
    const { data } = this.props
    const { email } = data.siteSettings.options.locations[0]
    window.location = `mailto:${decodeEntities(email)}`
  }

  render() {
    const { data } = this.props
    const {
      legalNavigation,
      footerNavigation,
      siteSettings,
      wordpressWpSettings,
    } = data

    const { items: legalNav } = legalNavigation
    const { items: footerNav } = footerNavigation
    const {
      navigationInFooter,
      contactDetailsInFooter,
      socialLinksFooter,
    } = siteSettings.options
    const { contact_hours } = siteSettings.options.locations[0]
    const currentYear = new Date().getFullYear()
    return (
      <>
        <footer>
          <div className="footer-main">
            <div className="inner">
              <div className="wrap">
                {contactDetailsInFooter && (
                  <ContactUsBlock
                    contactDetails={siteSettings.options.locations[0]}
                    displaySocials={socialLinksFooter}
                  />
                )}
                {contact_hours && (
                  <OpeningHoursBlock openingHours={contact_hours} />
                )}
                {navigationInFooter && (
                  <FooterNavigationBlock footerNav={footerNav && footerNav} />
                )}
              </div>
            </div>
          </div>
          <div className="disclaimers">
            <div className="wrap">
              {legalNav && (
                <div className="navigation">
                  <ul>
                    {legalNav.map((item, index) => (
                      <li key={index}>
                        <GatsbyLink to={item.url} decode>
                          {item.title}
                        </GatsbyLink>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="copyright">
                <span>
                  ©{currentYear} {wordpressWpSettings.title}
                </span>
              </div>
            </div>
          </div>
        </footer>
        {/* {privacyCookiePolicyNotice &&
          <CookiePolicy content={privacyCookiePolicyNoticeText}/>
        } */}
      </>
    )
  }
}

const ContactUsBlock = props => {
  const { contactDetails, displaySocials } = props
  const {
    email,
    addressDisplay,
    addressStreet,
    addressCity,
    addressRegion,
    addressPostCode,
    phone,
  } = contactDetails
  console.log(displaySocials)
  return (
    <div className="contact-details">
      <span className="contact-details-title">Contact Us</span>
      {phone && (
        <div className="phone-display">
          <a
            href={`tel:${phone.replace(/[^A-Z0-9]/gi, '')}`}
            className="control-call"
            aria-label="Call Now"
          >
            <span className="mobile-only">Phone</span>
            <span className="phone-display__phone">{phone}</span>
          </a>
        </div>
      )}
      {email && (
        <div className="email-display">
          <span className="mobile-only">Email</span>
          <a
            href={`mailto:${decodeEntities(email)}`}
            onClick={event => this.sendEmail(event)}
          >
            <span dangerouslySetInnerHTML={{ __html: email }} />
          </a>
        </div>
      )}
      {addressDisplay && (
        <div className="address-display">
          <span className="mobile-only">Location</span>
          <span className="address-first-line">{addressStreet}</span>
          <span className="address-second-line">
            {addressCity} {addressRegion} {addressPostCode}
          </span>
        </div>
      )}
      <div className="icon-wrapper">
        <div className="icon-wrapper-inner">
          {email && (
            <a
              href={`mailto:${decodeEntities(email)}`}
              onClick={event => this.sendEmail(event)}
            >
              <FaEnvelope />
            </a>
          )}
          {addressDisplay && (
            <a href="https://g.page/Keys-Dental?share" target="_blank">
              <FaMapPin />
            </a>
          )}
          {phone && (
            <a
              href={`tel:${phone.replace(/[^A-Z0-9]/gi, '')}`}
              aria-label="Call Now"
            >
              <FaPhone />
            </a>
          )}
          {displaySocials && <SocialLinks />}
        </div>
      </div>
      <div className="contact-details--image-wrapper">
        <div className="contact-details--accreditation--wrapper">
          <img
            src={footerAccreditation}
            alt="Dental Practice Accredited Badge for quality innovation performance"
          />
        </div>
        <div className="contact-details--zip--wrapper">
          <img src={footerZipPay} alt="Zippay" />
        </div>
        <div className="contact-details--humm--wrapper">
          <img src={footerHumm} alt="Humm" />
        </div>
        <div className="contact-details--super--wrapper">
          <img src={footerSuper} alt="Access My Super" />
        </div>
        <div className="contact-details--afterpay--wrapper">
          <img src={footerAfterpay} alt="Afterpay" />
        </div>
      </div>
    </div>
  )
}

const OpeningHoursBlock = props => {
  const { openingHours } = props
  return (
    <div className="opening-hours-block">
      <span className="opening-hours-block-title">Opening Hours</span>
      <div className="opening-hours-block-list">
        {openingHours &&
          openingHours.map(time => (
            <div key={time.days} className="line">
              <span>
                <strong>{time.days}</strong>
              </span>
              <span>{time.hours}</span>
            </div>
          ))}
      </div>
    </div>
  )
}

const FooterNavigationBlock = props => {
  const { footerNav } = props
  return (
    <div className="footer-navigation-block">
      <span className="footer-navigation-block-title">Navigation</span>
      <ul className="footer-nav">
        {footerNav &&
          footerNav.map((item, index) => (
            <li key={index}>
              <GatsbyLink to={item.url} aria-label={item.title} decode>
                {item.title}
              </GatsbyLink>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressMenusMenusItems(
          slug: { eq: "legal-navigation" }
        ) {
          items {
            title
            url
            classes
            slug
          }
        }
        footerNavigation: wordpressMenusMenusItems(
          slug: { eq: "footer-navigation" }
        ) {
          items {
            title
            url
            classes
            slug
            children: child_items {
              title
              url
              classes
              slug
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              addressCity
              addressRegion
              addressStreet
              addressPostCode
              email
              phone
              contact_hours {
                days
                hours
              }
            }
            footerTagline
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        }
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
