import React from 'react';

export const Chevron = (props) => {
  let deg = '0deg';
  switch (props.direction) {
    case 'up':
      deg = '180deg';
      break;
    case 'right':
      deg = '270deg';
      break;
    case 'left':
      deg = '90deg';
      break;
    default:
      break;
  }
  return (
    <svg viewBox="0 0 24 14" {...props} style={{ transform: `rotate(${deg})` }}>
      <defs>
        <path id="prefix__a" d="M0 0h24v14H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          fill="#003A40"
          d="M22.316 0L11.99 10.947 1.684 0 0 1.79l11.148 11.84c.233.247.536.37.842.37.305 0 .61-.123.842-.37L24 1.79 22.316 0z"
          mask="url(#prefix__b)"
        />
      </g>
    </svg>
  )
}